/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/supply-chain-execution-lvl3.glb 
*/

import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshBasicMaterial,MeshPhysicalMaterial } from 'three'
// import * as THREE from 'three'
import Hex from './components/Hex/Hex'

import gsap from "gsap";

export function Model5(props) {
  const { nodes, materials } = useGLTF('./glb/supply-chain-planning-lvl3-empty2.glb')
  const active = props.active
  const anim = props.anim
  const isAnim = props.isAnim 
  const isActive = props.isActive
  const scene = props.scene
  const isScene = props.isScene
  const level = props.level
  const isLevel = props.isLevel
  const direction = props.direction
  const prev = props.prev
  const prevActive = props.prevActive
  const sceneCtx = props.sceneCtx
  const component = useRef()
  const context = useRef()

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new MeshPhysicalMaterial();          
        MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new MeshBasicMaterial();          
        MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  useEffect(() => {
    if (isScene === props.name && isActive === props.title) {
      sceneCtx(context.current)
      context.current.playTimeline()
    }
  },[isActive,isScene,props.name,props.title,sceneCtx])


  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.5
        });
      tl.addLabel('scene3');

      // let smDur = 0.3
      // 0.25
      let mdDur = 0.4
      // 0.4
      // let lgDur2 = 0.55
      // 0.5
      let lgDur = 0.65
      let dl = 0.25

      component.current.children.forEach((mesh) => {
        if (mesh.isGroup && mesh.name === 'better-insights') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,       
          }, 'scene3')
          .from(mesh.position, {
            x: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              // console.log(mesh.material);
              // const color = new Color("#ed2a22");
              mesh.material = copyMaterial(mesh)
              // mesh.material.color.copy(color);
              // mesh.material.map = null
              // mesh.material.ior = 1
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0
              // mesh.material.roughness = 0.8
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, {
                opacity: 0,
                delay: dl,
                duration: mdDur, 
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }          
              }, 'scene3+=' + mdDur)
              mesh.children?.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    delay: dl,
                    duration: mdDur,  
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }        
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }

        if (mesh.isGroup && mesh.name === 'improve-exception-management') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            // immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene3')
          .from(mesh.position, {
            x: '+=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              // const color = new Color("#ed2a22");
              mesh.material = copyMaterial(mesh)
              // mesh.material.color.copy(color);
              // mesh.material.map = null
              // mesh.material.ior = 1
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0
              // mesh.material.roughness = 0.8
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene3+=' + mdDur)
              mesh.children?.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }

        if (mesh.isGroup && mesh.name === 'fine-tune-demand') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            // immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene3')
          .from(mesh.position, {
            x: '+=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              // const color = new Color("#ed2a22");
              mesh.material = copyMaterial(mesh)
              // mesh.material.color.copy(color);
              // mesh.material.map = null
              // set from original
              // mesh.material.ior = 1.45
              // mesh.material.alpha = 0.882
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0.724
              // mesh.material.roughness = 0.216
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {
                transparent: true,
                // opacity:0.9
              })
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene3+=' + mdDur)
              mesh.children?.forEach((mesh) => {

                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'back') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene3')
          .from(mesh.position, {
            x: '-=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')      
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }   
              }, 'scene3+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }
      })
      self.add("playTimeline", () => tl.play('scene3'));
      self.add("reverseTimeline", () => tl.reverse());
    }, component);
    context.current = ctx;
    return () => ctx.revert();

  }, []);
  // const lightPos = useControls({
  //   intensity: {
  //     value: 670,
  //     min: 0,
  //     max: 1230
  //   },
  //   x: {
  //     value: 1,
  //     min: -10,
  //     max: 10
  //   },
  //   y: {
  //     value: -1,
  //     min: -10,
  //     max: 10
  //   },
  //   z: {
  //     value: -1,
  //     min: -10,
  //     max: 10
  //   },
  //   scale: {
  //     value: 2,
  //     min: 0,
  //     max: 10
  //   }
  //   // anotherVec3: [3, 1, 1],
  // })
  // const pointLightRef = useRef(null);

  // useHelper(pointLightRef, PointLightHelper, 1, "red");

  return (
    <group ref={component} {...props} dispose={null}>
     
      <Hex  
      name="fine-tune-demand"    
      title={`Fine-Tune\nDemand\nForecasts`}      
      geometry={nodes.Cylinder018.geometry} 
      material={materials['med-red-hex-6_BAKED']}
      position={[4.246, -13.8, -6.4]}
      dest="04"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      intensity={0}
      titlePosition ={[-0.05, 1,0.15]}
      > 
      {/* <pointLight 
        name="top2" 
        intensity={lightPos.intensity} 
        decay={2} 
        position={[lightPos.x,lightPos.y,lightPos.z]}
        rotation={[-Math.PI, 0.004, -Math.PI]}
        scale={lightPos.scale} 
        ref={pointLightRef}
        /> */}
      {/* <mesh geometry={nodes.Warehouse_Management002.geometry} material={materials['White text.003']} position={[.025, .414, -0.023]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="improve-exception-management"  
      title={`Improve\nException\nManagement`}       
      geometry={nodes.Cylinder003.geometry} 
      material={materials['med-red-hex-6_BAKED']}
      position={[3.90, -13.8, 6.2]}
      dest="04"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      titlePosition ={[-0.02, 1,-0.1]}
      > 
      {/* <pointLight title="btm-right" intensity={795.775} decay={2} position={[12, 10,2]} rotation={[-1.913, 0.003, 0.706]} /> */}

      {/* <directionalLight color="red" position={[3.225, -19.459, 3.023]} rotation={[-1.913, 0.003, 0.706]} scale={0.393} /> */}
      {/* <pointLight intensity={1397.887} decay={2} position={[.025, -19.459, -0.023]} rotation={[-1.913, 0.003, 0.706]} scale={0.393} /> */}
      {/* <mesh geometry={nodes.Warehouse_Management001.geometry} material={materials['White text.003']} position={[.025, .414, -0.023]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="better-insights"       
      title={`Get Better\nInsights`}      
      geometry={nodes.Cylinder016.geometry} 
      material={materials['med-red-hex-6_BAKED']}
      position={[-7.428, -13.8, 1.889]}
      dest="04"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      titlePosition ={[0.12, 1,0.123]}
      > 
        {/* <pointLight title="btm-left" intensity={795.775} decay={2} position={[-1, 10,2]} rotation={[-1.913, 0.003, 0.706]} /> */}

      {/* <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[.025, .414, -0.023]} scale={0.75} /> */}
      </Hex>
      {/* <mesh geometry={nodes.Cylinder016.geometry} material={materials['med-red-hex-6_BAKED']}position={[-5.728, -15.627, 1.889]} /> */}
     

      {/* <mesh geometry={nodes.Cylinder003.geometry} material={materials['sce-lvl3-med-orange-baked']} position={[3.696, -15.627, 5.797]} />
      <mesh geometry={nodes.Cylinder016.geometry} material={materials['sce-lvl3-med-orange-baked']} position={[-6.711, -15.627, 1.975]} />
      <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[-6.747, -15.244, 1.833]} scale={0.75} />
      */}
      <Hex  
      name="back"    
      title="← Back"     
      geometry={nodes.Cylinder038.geometry} 
      material={materials['lvl2-large-clearblue-baked-texture']}
      position={[-4.777, -13.8, -7.011]}
      dest="02"
      isDirection="up"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      >
        {/* <mesh geometry={nodes.Cylinder038.geometry} material={materials['lvl2-large-clearblue-baked-texture']} position={[-4.077, -15.627, -5.362]} />  */}
        {/* <mesh geometry={nodes.Back.geometry} material={materials['White text.003']} position={[0, .414, 0]} scale={0.75} /> */}
      </Hex>

      {/* <Hex           
      geometry={nodes.Cylinder038.geometry} 
      material={materials['lvl2-large-clearblue-baked-texture']}
      position={[-4.077, -15.627, -5.362]}
      dest="02"
      isDirection="up"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scene={scene}
      timeline={timeline2}
      prev={prev}
      /> */}
      {/* <mesh geometry={nodes.Back.geometry} material={materials['White text.003']} position={[-4.113, -15.244, -5.279]} scale={0.75} /> */}
    </group>
  )
}

useGLTF.preload('./glb/supply-chain-planning-lvl3-empty2.glb')
