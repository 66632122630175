/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/level1v9.glb 
*/
import React, { useLayoutEffect,useRef, useState, useEffect} from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
// import { PointLight, PointLightHelper } from "three";
import Hex from './components/Hex/Hex'
import gsap from "gsap";
// import { useControls } from 'leva'

export function Model(props) {
  
  const active = props.active
  const isActive = props.isActive 
  const anim = props.anim
  const isAnim = props.isAnim 
  const scene = props.scene
  const level = props.level
  const isLevel = props.isLevel
  const topCtx = props.topCtx
  const isScene = props.isScene // 01
  const direction = props.direction
  const prev = props.prev
  const prevActive = props.prevActive
  const component = useRef()
  const context = useRef()
  const topSceneCtx = props.topSceneCtx
  const [isSet, setisSet] = useState(false);

  useEffect(() => {
    if (isScene === props.name && !isSet) {
      console.log('setting topSceneCTX')
      setisSet(true)
      topSceneCtx(context.current)
    }  
  },[isScene,props.name,isSet,topSceneCtx])

  const { nodes, materials } = useGLTF('./glb/Level1.glb')

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new THREE.MeshPhysicalMaterial();          
        THREE.MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new THREE.MeshBasicMaterial();          
        THREE.MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.35
      });
      tl.addLabel('topLoad');  
      tl.delay(0.2)
      let tl3 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      let tl4 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      tl4.addLabel('topHex2'); 
      let tl5 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      tl5.addLabel('topHex3'); 
      let tl6 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      tl6.addLabel('topHex4'); 
      let tl7 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      tl7.addLabel('topLoad2'); 
      let i = 0  
      let hexSmall = []
      // 0.15
      let smDur = 0.3
      // 0.25
      let mdDur = 0.4
      // 0.4
      let lgDur2 = 0.55
      // 0.5
      let lgDur = 0.65
      let rDel = 0.75
      let rDelLg = 1.2
      // let rDelLg = 1.2
      let rDelSm = 0.25

      // console.log('isActive ' + isActive)
      // console.log(rDel)
      self.add("omniChannel", () => {
        component.current.children.forEach((mesh) => {     
          if (mesh.isGroup && mesh.name === 'omnichannel') {  
            let hexMesh = mesh.children[0].children[0];     
            tl5
            .to(mesh.position, {
              x: '-=4.5',
              z: '+=4.5',
              duration: lgDur,
              ease: "back.in",
            }, 'topHex3+='+ smDur)
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,        
              delay: mdDur,        
            }, 'topHex3+=' + lgDur2)
            mesh.children[0].children.forEach((mesh) => {
              if (mesh.isMesh) {
                // console.log(mesh.material)
                mesh.material = copyMaterial(mesh)
                gsap.set(mesh.material, {transparent: true})
                tl5
                .to(mesh.material, { 
                  opacity: 0,
                  duration: mdDur,
                  onUpdate: function() {
                    if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                      mesh.material.visible = true
                      hexMesh.visible = true;
                    } else {
                      mesh.material.visible = false
                      hexMesh.visible = false;
                    }
                  }       
                }, 'topHex3+=' + lgDur2)
                mesh.children.forEach((mesh) => {
                  if (mesh.isMesh) {
                    // console.log(mesh.material)
                    mesh.material = copyMaterial(mesh)
                    gsap.set(mesh.material, {transparent: true})
                    tl5
                    .to(mesh.material, {
                      opacity: 0,
                      duration: mdDur,
                      onUpdate: function() {
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }
                      }             
                    }, 'topHex3+=' + lgDur2)
                  } else {
                    // console.log(mesh)
                    tl5
                    .to(mesh, {
                      opacity: 0,                      
                      duration: mdDur,
                      onUpdate: function() {
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }
                      }      
                    }, 'topHex3+=' + lgDur2)
                  }
                })
              }
            })
          }
        })
        tl5.play('topHex3')
      })
      self.add("supplyChainExecution", () => { 
        component.current.children.forEach((mesh) => {   
          if (mesh.isGroup && mesh.name === 'supply-chain-execution') {   
            let hexMesh = mesh.children[0].children[0];
            tl4 
            .to(mesh.position, {
              x: '+=4.5',
              z: '+=4.5',
              duration: lgDur,
              ease: "back.in",
            }, 'topHex2+=' + smDur)
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,        
              delay: mdDur,        
            }, 'topHex2+=' + lgDur2)
            mesh.children[0].children.forEach((mesh) => {
              if (mesh.isMesh) {
                mesh.material = copyMaterial(mesh)
                tl4
                .to(mesh.material, { 
                  opacity: 0,
                  duration: mdDur,
                  onUpdate: function() {
                    if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                      mesh.material.visible = true
                      hexMesh.visible = true;
                    } else {
                      mesh.material.visible = false
                      hexMesh.visible = false;
                    }
                  }     
                }, 'topHex2+=' + lgDur2)
                mesh.children.forEach((mesh) => {
                  if (mesh.isMesh) {
                    mesh.material = copyMaterial(mesh)
                    gsap.set(mesh.material, {transparent: true})
                    tl4
                    .to(mesh.material, { 
                      opacity: 0,
                      duration: mdDur, 
                      onUpdate: function() {
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }
                      }  
                    }, 'topHex2+=' + lgDur2)
                  } 
                })
              }
            })
          }
        })
        tl4.play('topHex2')
      })
      self.add("supplyChainPlanning", () => {
        component.current.children.forEach((mesh) => {   
          if (mesh.isGroup && mesh.name === 'supply-chain-planning') {  
            let hexMesh = mesh.children[0].children[0];
            tl3
            .to(mesh.position, {
              x: '-=4.5',
              z: '-=4.5',
              duration: lgDur,
              ease: "back.in",
            }, 'topHex+=' + smDur)
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,        
              delay: mdDur,        
            }, 'topHex+=' + lgDur2)
            mesh.children[0].children.forEach((mesh) => {
              if (mesh.isMesh) {
                mesh.material = copyMaterial(mesh)
                gsap.set(mesh.material, {transparent: true})
                tl3
                .to(mesh.material, { 
                  opacity: 0,
                  duration: mdDur, 
                  onUpdate: function() {
                    if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                      mesh.material.visible = true
                      hexMesh.visible = true;
                    } else {
                      mesh.material.visible = false
                      hexMesh.visible = false;
                    }
                  } 
                }, 'topHex+=' + lgDur2)
                mesh.children.forEach((mesh) => {
                  if (mesh.isMesh) {
                    mesh.material = copyMaterial(mesh)
                    gsap.set(mesh.material, {transparent: true})
                    tl3
                    .to(mesh.material, { 
                      opacity: 0,
                      duration: mdDur, 
                      onUpdate: function() {
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }

                      } 
                    }, 'topHex+=' + lgDur2)
                  }
                })
              }
            })
          }
        })
        tl3.play('topHex')
      })
      
      self.add("activePlatform", () => {
        component.current.children.forEach((mesh) => {        
          if (mesh.isGroup && mesh.name === 'active-platform') {   
            let hexMesh = mesh.children[0].children[0];
            tl6
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,        
              delay: mdDur,        
            }, 'topHex4+=0.1')
            mesh.children[0].children.forEach((mesh) => {
              if (mesh.isMesh) {
                mesh.material = copyMaterial(mesh)
                gsap.set(mesh.material, {transparent: true})
                tl6
                .to(mesh.material, { 
                  opacity: 0,
                  duration: 0.2,
                  onUpdate: function() {
                    if (mesh.material.opacity < 1) {
                      mesh.material.depthTest = false;
                    } else {
                      mesh.material.depthTest = true;
                    }
                    if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                      mesh.material.visible = true
                      hexMesh.visible = true;
                    } else {
                      mesh.material.visible = false
                      hexMesh.visible = false;
                    }
                  } 
                       
                }, 'topHex4')
                mesh.children.forEach((mesh) => {
                  if (mesh.isMesh) {
                    mesh.material = copyMaterial(mesh)
                    gsap.set(mesh.material, {transparent: true})
                    tl6
                    .to(mesh.material, { 
                      opacity: 0,
                      duration: 0.2,  
                      onUpdate: function() {
                        if (mesh.material.opacity < 1) {
                          mesh.material.depthTest = false;
                        } else {
                          mesh.material.depthTest = true;
                        }
                        if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                          mesh.material.visible = true
                        } else {
                          mesh.material.visible = false
                        }
                      }       
                    }, 'topHex4')
                  }
                })
              }
            })
            tl6.play('topHex4')
          }
        })
      })
    self.add("omniChannelRev", () => {
      gsap.delayedCall(rDel, function(){    
        tl5.reverse();    
     });  
    });
    self.add("activePlatformRev", () => {
      gsap.delayedCall(0.3, function(){    
        tl6.reverse();    
     });  
    });
    self.add("supplyChainPlanningRev", () => {
      gsap.delayedCall(rDel, function(){    
        tl3.reverse();    
     });  
    });
    self.add("supplyChainExecutionRev", () => {
      gsap.delayedCall(rDel, function(){    
        tl4.reverse();    
     });  
    });
    self.add("omniChannelFastRev", () => {
      gsap.delayedCall(rDelSm, function(){    
        tl5.reverse();    
     });  
    });
    self.add("supplyChainPlanningFastRev", () => {
      gsap.delayedCall(rDelSm, function(){    
        tl3.reverse();    
     });  
    });
    self.add("supplyChainExecutionFastRev", () => {
      gsap.delayedCall(rDelSm, function(){    
        tl4.reverse();    
     });  
    });
    self.add("shiftLightsLvl3", () => {
      component.current.children.forEach((mesh) => {   
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '+=20',
            x: '-=2',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'bottom-left') {  
          gsap
          .to(mesh.position, { 
            y: '+=15',
            x: '+=1',
            z: '-=1',
            delay: smDur,
            duration: smDur,        
          })
        }

        if (mesh.name === 'btm-left' || mesh.name === 'btm-right' || mesh.name === 'tp-right') {  
          gsap.to(mesh, { 
            intensity: 795.775,
            // duration: 0.2,
            delay: smDur,
            duration: smDur,    
          })
        }
        
      });
    });
    self.add("shiftLightsLvl3Active", () => {
      component.current.children.forEach((mesh) => {   
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '+=40',
            x: '-=24',
            // z: '-=1',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'bottom-left') {  
          gsap
          .to(mesh.position, { 
            y: '+=15',
            x: '+=1',
            z: '-=1',
            delay: smDur,
            duration: smDur,        
          })
        }

        if (mesh.name === 'btm-left' || mesh.name === 'btm-right' || mesh.name === 'tp-right') {  
          gsap.to(mesh, { 
            intensity: 795.775,
            // duration: 0.2,
            delay: smDur,
            duration: smDur,    
          })
        }
        
      });
    });
    self.add("shiftLightsLvl3ActiveRev", () => {
      component.current.children.forEach((mesh) => {   
        // console.log(mesh.name)
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '-=40',
            x: '+=24',
            // z: '-=1',
            delay: lgDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'bottom-left') {  
          gsap
          .to(mesh.position, { 
            y: '-=15',
            x: '-=1',
            z: '+=1',
            delay: smDur,
            duration: smDur,        
          })
        }

        if (mesh.name === 'btm-left' || mesh.name === 'btm-right' || mesh.name === 'tp-right') {  
          gsap.to(mesh, { 
            intensity: 0,
            // duration: 0.2,
            delay: smDur,
            duration: smDur,    
          })
        }
        
      });
    });
    self.add("shiftLightsLvl3Rev", () => {
      gsap.delayedCall(rDel, function(){   
        component.current.children.forEach((mesh) => {   
          if (mesh.name === 'center') {  
            gsap
            .to(mesh.position, { 
              // y: '10',
              // x: '22',
              // z: '0.5',
              y: '-=20',
              x: '+=2',
              delay: smDur,
              duration: smDur,        
            })
          }
          if (mesh.name === 'bottom-left') {  
            gsap
            .to(mesh.position, { 
              y: '-=15',
              x: '-=1',
              z: '+=1',
              // delay: smDur,
              duration: smDur,        
            })
          }
          if (mesh.name === 'btm-left' || mesh.name === 'btm-right' || mesh.name === 'tp-right') {  
            gsap.to(mesh, { 
              intensity: 0,
              // duration: 0.2,
              delay: smDur,
              duration: smDur,    
            })
          }
        });
      })
    });
    self.add("shiftLights", () => {
      component.current.children.forEach((mesh) => {   
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '+=20',
            x: '-=9',
            z: '-=6',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-left') {  
          gsap
          .to(mesh.position, { 
            y: '+=2',
            x: '+=1.3',
            z: '+=1.3',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'mid-right') {  
          gsap
          .to(mesh.position, { 
            x: '+=0.8',
            z: '+=0.8',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-right') {  
          gsap
          .to(mesh.position, { 
            x: '+=1.4',
            z: '-=.55',
            delay: smDur,
            duration: smDur,        
          })
        }
      })
    })
    self.add("shiftLightsRev", () => {
      component.current.children.forEach((mesh) => {   
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '-=20',
            x: '+=9',
            z: '+=6',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-left') {  
          gsap
          .to(mesh.position, { 
            y: '-=2',
            x: '-=1.3',
            z: '-=1.3',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'mid-right') {  
          gsap
          .to(mesh.position, { 
            x: '-=0.8',
            z: '-=0.8',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-right') {  
          gsap
          .to(mesh.position, { 
            x: '-=1.1',
            z: '+=.55',
            delay: smDur,
            duration: smDur,        
          })
        }
      })
    })
    self.add("shiftLightsActive", () => {
      component.current.children.forEach((mesh) => {   
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '+=2.5',
            duration: smDur,        
          })
        }

        if (mesh.name === 'bottom-left') {  
          gsap
          .to(mesh.position, { 
            y: '+=4',
            x: '+=1',
            z: '-=1',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-left') {  
          gsap
          .to(mesh.position, { 
            y: '+=2',
            x: '+=1.3',
            z: '+=1.3',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-right') {  
          gsap
          .to(mesh.position, { 
            // y: '-=4',
            x: '+=.75',
            z: '-=.65',
            delay: smDur,
            duration: smDur,        
          })
        }
      })
    })
    self.add("shiftLightsActiveRev", () => {
      component.current.children.forEach((mesh) => {   
        if (mesh.name === 'center') {  
          gsap
          .to(mesh.position, { 
            y: '-=2.5',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'bottom-left') {  
          gsap
          .to(mesh.position, { 
            y: '-=4',
            x: '-=1',
            z: '+=1',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-left') {  
          gsap
          .to(mesh.position, { 
            // y: '-=2',
            x: '-=1.3',
            z: '-=1.3',
            delay: smDur,
            duration: smDur,        
          })
        }
        if (mesh.name === 'top-right') {  
          gsap
          .to(mesh.position, { 
            // y: '-=4',
            x: '-=.75',
            z: '+=.65',
            delay: smDur,
            duration: smDur,        
          })
        }
      })
    })


      component.current.children.forEach((mesh) => {   
        if (mesh.isGroup && mesh.name === 'active-platform') {   
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.transparent = true
              // mesh.material.transmission = 0.2
              gsap.set(mesh.material, {
                transparent: true, 
                opacity:0.65
              })
            }
          })
        }  
        if (mesh.isMesh && mesh.material === materials['honeycomb-20_BAKED']) {
          hexSmall.push(mesh); 
        }
        if (mesh.isMesh && mesh.material === materials['med-clearblue-hex-texture_BAKED']) {
          mesh.material = copyMaterial(mesh)
          mesh.material.transparent = true
          mesh.material.opacity = 1
          mesh.material.transmission = 1

          if (i === 0) {
            tl
            .to(mesh.position, { 
              x: '+=3.5',
              z: '-=3.5',
              ease: "back.in",
              duration: lgDur,        
            }, 'topLoad')
            tl
            .to(mesh.material, { 
              opacity: 0,
              duration: mdDur,        
            }, 'topLoad+=' + smDur)
            // hack to fix visibility rendering issues
            tl
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,
              delay: mdDur,        
            }, 'topLoad+=' + smDur)
          } 
          if (i === 1) {
            tl
            .to(mesh.position, { 
              x: '+=3.5',
              z: '+=3.5',
              ease: "back.in",
              duration: lgDur,        
            }, 'topLoad')
            .to(mesh.material, { 
              opacity: 0,
              duration: mdDur,        
            }, 'topLoad+=' + smDur)
            // hack to fix visibility rendering issues
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,        
              delay: mdDur,        
            }, 'topLoad+=' + smDur)
          }   
          if (i === 2) {
            tl
            .to(mesh.position, { 
              x: '-=3.5',
              // z: '+=2',
              ease: "back.in",
              duration: lgDur,        
            }, 'topLoad')
            .to(mesh.material, { 
              opacity: 0,
              duration: mdDur,        
            }, 'topLoad+=' + smDur)
            // hack to fix visibility rendering issues
            .to(mesh.position, { 
              y: '-=18',
              duration: 0,        
              delay: mdDur,        
            }, 'topLoad+=' + smDur)
          }      
          i++
        }      
      });
      self.add("playTimeline", () => tl.play('topLoad'));
      self.add("reverseTimeline", () => {
        gsap.delayedCall(rDelLg, function(){    
          tl.reverse();    
       });  
      });
      self.add("reverseTimelineFast", () => {
        gsap.delayedCall(rDelSm, function(){    
          tl.reverse();    
       });  
      });
    }, component);
    context.current = ctx;
    return () => ctx.revert();
  }, [component,materials]);

  // const lightPos = useControls({
    // position={[19.1, -0.5, 6.8]} 
    // intensity: {
    //   value: 670,
    //   min: 0,
    //   max: 1230
    // },
    // x: {
    //   value: 20,
    //   min: -20,
    //   max: 40
    // },
    // y: {
    //   value: -0.5,
    //   min: -20,
    //   max: 30
    // },
    // z: {
    //   value: 6.8,
    //   min: -20,
    //   max: 30
    // },
    // scale: {
    //   value: 2,
    //   min: 0,
    //   max: 10
    // }
    // anotherVec3: [3, 1, 1],
  // })
  // const pointLightRef = useRef(null);

  // useHelper(pointLightRef, PointLightHelper, 1, "red");
    
  // const { intensity, width, height, color } = useControls({ intensity: { value: 1, min: 0, max: 20 }, width: { value: 3, min: 1, max: 10 }, height: { value: 3, min: 1, max: 10 }, color: '#fff' });
  return (
    <group ref={component}  {...props} dispose={null}>
      {/* <ambientLight intensity={3.2} /> */}
      <pointLight name="btm-left" 
      intensity={0} decay={2} 
      position={[-7.5, -3.0, 5.3]} 
      rotation={[-1.913, 0.003, 0.706]} 
      // position={[lightPos.x,lightPos.y,lightPos.z]}
      // ref={pointLightRef}
      />
      <pointLight name="btm-right" intensity={0} decay={2} 
        position={[19.1, -0.5, 6.8]} 
        rotation={[-1.913, 0.003, 0.706]} 
      // position={[lightPos.x,lightPos.y,lightPos.z]}
      // ref={pointLightRef}
        />
      <pointLight name="tp-right" intensity={0} decay={2} 
        position={[10.5, -1.8, -12]} 
        rotation={[-1.913, 0.003, 0.706]} 
        // ref={pointLightRef}
        // position={[lightPos.x,lightPos.y,lightPos.z]}
      // ref={pointLightRef}
        />

      
      
      <pointLight intensity={795.775} decay={2} position={[-14.344, -8.566, 1.66]} rotation={[-1.913, 0.003, 0.706]} />
      <pointLight 
        name="mid-right" 
        intensity={397.887} 
        decay={2} 
        position={[12.226, -9.459, 3.756]} 
        // position={lightPos.midRight}
        rotation={[-1.913, 0.003, 0.706]} 
        // ref={pointLightRef.current}
        scale={0.393} />
      <pointLight 
        name="top" 
        intensity={795.775} 
        decay={2} 
        position={[5.122, -6.314, -18.223]} 
        rotation={[-1.913, 0.003, 0.706]} 
        scale={0.393} 
        />
      {/* <pointLight 
        name="top2" 
        intensity={lightPos.intensity} 
        decay={2} 
        position={[lightPos.x,lightPos.y,lightPos.z]}
        rotation={[-Math.PI, 0.004, -Math.PI]}
        scale={lightPos.scale} 
        ref={pointLightRef}
        /> */}
      <pointLight intensity={397.887} decay={2} position={[7.099, -2.105, 10.486]} rotation={[-1.913, 0.003, 0.706]} scale={0.393} />
      <pointLight name="bottom-left" intensity={795.775} decay={2} position={[-9.291, -11.122, 9.914]} rotation={[-1.913, 0.003, 0.706]} />
      <pointLight name="top-left" intensity={795.775} decay={2} position={[-11.146, -6.097, -12.755]} rotation={[-1.913, 0.003, 0.706]} />
      <pointLight name="top-right" intensity={397.887} decay={2} position={[9.438, -8.35, -10.431]} rotation={[-1.913, 0.003, 0.706]} scale={0.393} />
      <pointLight name="center" 
        intensity={557.042} 
        decay={2} 
        position={[0.103, -4.471, -0.865]} 
        rotation={[-1.913, 0.003, 0.706]} 
        // position={[lightPos.x,lightPos.y,lightPos.z]}
        // ref={pointLightRef}
        scale={0.393} />
      <mesh geometry={nodes.Cylinder044.geometry} material={materials['med-clearblue-hex-texture_BAKED']} position={[4.231, -14.234, -7.284]} rotation={[-Math.PI, 0.004, -Math.PI]} scale={[0.978, 0.495, 0.978]} />
      <mesh geometry={nodes.Cylinder003.geometry} material={materials['med-clearblue-hex-texture_BAKED']} position={[4.221, -14.234, 7.285]} rotation={[-Math.PI, 0.004, -Math.PI]} scale={[0.978, 0.495, 0.978]} />
      <mesh geometry={nodes.Cylinder009.geometry} material={materials['med-clearblue-hex-texture_BAKED']} position={[-8.548, -14.234, 0.068]} rotation={[-Math.PI, 0.004, -Math.PI]} scale={[0.978, 0.495, 0.978]} />
      <Hex   
      title="Platform"
      text={`Cloud Native\nEvergreen\nIntelligent\nExtensible`}
      textColor="#16192C"
      name="active-platform" 
      fontSize = {1.2}       
      geometry={nodes.Cylinder039.geometry} 
      material={materials['large-clear-texture-1_BAKED']} 
      position={[-0.121, -14.8, 0.897]}
      textPosition={[-0.75, .939, 1.042]}
      titlePosition={[0.12, .939, -0.61]}
      dest="02"
      isActive={isActive}
      active={active}
      isAnim={isAnim}
      anim={anim}
      isDirection="down"
      direction={direction}
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      topCtx={topCtx}
      level={level}
      isLevel={isLevel}
      >
        <mesh name="logo" geometry={nodes.Curve002.geometry} material={materials['Blue Text.001']} position={[-2.24, .939, -0.21]} scale={[0.977, 0.846, 0.977]} />
        <mesh geometry={nodes.lines.geometry} material={materials['Blue Text.001']} position={[-1.208, .939, 1.042]} scale={[1, 1, 1.119]} />
      </Hex>
     <Hex   
      title={`Supply Chain\nExecution`}
      text={`Warehouse Management\nLabor Management\nTransportation Management\nCarrier Management`}
      name="supply-chain-execution"        
      geometry={nodes.darkblue3.geometry} 
      material={materials['large-grey-hex-texture-6_BAKED']}
      position={[7.008, -13.813, 0.045]}
      dest="02"
      isAnim={isAnim}
      anim={anim}
      isActive={isActive}
      isDirection="down"
      direction={direction}
      active={active}
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      topCtx={topCtx}
      level={level}
      isLevel={isLevel}
      >
        <mesh geometry={nodes.Cylinder048.geometry} material={materials.orange_tile} position={[0.073, .904, 3.163]} scale={[0.44, 0.182, 0.44]} />
        <mesh geometry={nodes.lines001.geometry} material={materials.orange_tile} position={[-2.44, .94, .856]} scale={[1.012, 1.012, 1.119]} />
      </Hex>
      <Hex  
      title={`Supply Chain\nPlanning`}
      name="supply-chain-planning"     
      text={`Demand Forecasting\nReplenishment\nVendor Managed Inventory\nAllocation`}   
      geometry={nodes.darkblue2001.geometry} 
      material={materials['large-grey-hex-texture-6_BAKED']}
      position={[-3.498, -13.8, -5.994]}
      dest="02"
      isAnim={isAnim}
      anim={anim}
      isActive={isActive}
      isDirection="down"
      direction={direction}
      active={active}
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      topCtx={topCtx}
      level={level}
      isLevel={isLevel}
      >
        <mesh geometry={nodes.Cylinder049.geometry} material={materials.red_tile} position={[-0.05, .904, 3.163]} scale={[0.44, 0.182, 0.44]} />
        <mesh geometry={nodes.lines002.geometry} material={materials.red_tile} position={[-2.44, .93, .856]} scale={[1.016, 1.016, 1.111]} />
      </Hex>
      <Hex  
      title={`Omnichannel\nCommerce`}  
      text={`Order Management\nPoint of Sale\nStore Inventory & Fullfillment\nCustomer Service & Engagement`}
      name="omnichannel"        
      geometry={nodes.darkblue2.geometry} 
      material={materials['large-grey-hex-texture-6_BAKED']}
      position={[-3.517, -13.8, 6.13]}
      dest="02"
      isDirection="down"
      isActive={isActive}
      isAnim={isAnim}
      anim={anim}
      direction={direction}
      active={active}
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      topCtx={topCtx}
      level={level}
      isLevel={isLevel}
      >
      <mesh geometry={nodes.Cylinder050.geometry} material={materials.yellow_tile} position={[-0.05, .904, 3.163]} scale={[0.44, 0.182, 0.44]} />
      <mesh geometry={nodes.lines003.geometry} material={materials.yellow_tile} position={[-2.44, .93, .856]} scale={[1.051, 1.051, 1.11]} />
      </Hex>
    </group>
  )
}

useGLTF.preload('./glb/Level1.glb')
