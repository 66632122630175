/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/supply-chain-execution-lvl2.glb 
*/

import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshBasicMaterial,MeshPhysicalMaterial,Color } from 'three'
// import * as THREE from 'three'
import Hex from './components/Hex/Hex'
import gsap from "gsap";

export function Model4(props) {
  const { nodes, materials } = useGLTF('./glb/supply-chain-planning-lvl2-empty2.glb')
  const active = props.active
  const anim = props.anim
  const isAnim = props.isAnim 
  const isActive = props.isActive
  const scene = props.scene
  const level = props.level
  const isLevel = props.isLevel
  const isScene = props.isScene
  const direction = props.direction
  const prev = props.prev
  const prevActive = props.prevActive
  const sceneCtx = props.sceneCtx
  const component = useRef()
  const context = useRef()

  useEffect(() => {
    if (isScene === props.name && isActive === props.title) {
      sceneCtx(context.current)
      context.current.playTimeline()
    }
  },[isActive,isScene,props.name,props.title,sceneCtx])


  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      // console.log(mesh)
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new MeshPhysicalMaterial();          
        MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new MeshBasicMaterial();          
        MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.5
        });
      // let smDur = 0.3
      let mdDur = 0.4
      // let lgDur2 = 0.55
      let lgDur = 0.65
      let dl = 0.25
      // let xlDur = 0.75
      tl.addLabel('scene2');
      component.current.children.forEach((mesh) => {
        if (mesh.isGroup && mesh.name === 'replenishment' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene2')     
          .from(mesh.position, {
            x: '+=2.5',
            // z: '-=4.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }    
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#B01500");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 0.5
                    mesh.material.roughness = 0.897
                    mesh.material.needsUpdate = true;
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,   
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }      
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'demand-forecasting' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene2')        
          .from(mesh.position, {
            x: '+=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }    
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#B01500");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 0.5
                    mesh.material.roughness = 0.897
                    mesh.material.needsUpdate = true;
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl, 
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }           
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'back') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2')
          .from(mesh.position, {
            x: '-=2.5',
            z: '-=2.5',
            duration: lgDur,
            ease: "back.in",
            delay: dl,
          }, 'scene2')      
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }          
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl, 
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }         
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'allocation' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,
          }, 'scene2')
          tl    
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2')   
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl, 
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }                 
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#B01500");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 0.5
                    mesh.material.roughness = 0.897
                    mesh.material.needsUpdate = true;
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,   
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }                
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'carrier-management' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          tl   
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2')      
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }                
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl, 
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }                  
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
      })
      self.add("playTimeline", () => tl.play('scene2'));
      self.add("reverseTimeline", () => tl.reverse());
    }, component);
    context.current = ctx;
    return () => ctx.revert();
  }, [isActive,prevActive]);


  return (
    <group ref={component} {...props} dispose={null}>
      <Hex  
      name="replenishment" 
      title={`Replenishment`}           
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      // position={[8.677, -16.813, 1.011]}
      position={[8, -13.8, 1.1]}
      dest="03"
      isDirection="down"
      isActive={isActive}
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      prev={prev}
      prevActive={prevActive}
      scene={scene}
      level={level}
      isLevel={isLevel}
      url="https://www.manh.com/solutions/supply-chain-planning-software/inventory-replenishment-system"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh name="title" geometry={nodes.Warehouse_Management001.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>
      <Hex  
      name="demand-forecasting"    
      title={`Demand\nForecasting`}      
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[5.077, -13.8, -7.011]}
      dest="03"
      isDirection="down"
      direction={direction}
      isActive={isActive}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      url="https://www.manh.com/solutions/supply-chain-planning-software/demand-forecasting-software"
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management002.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      {/* <Hex  
      name="carrier-management"          
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[-7.877, -13.8, .86]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      level={level}
      isLevel={isLevel}
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} />
      </Hex> */}

      <Hex  
      name="allocation" 
      title={`Allocation`}          
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[-3.5, -13.8, 7.25]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      prev={prev}
      prevActive={prevActive}
      isActive={isActive}
      scene={scene}
      level={level}
      isLevel={isLevel}
      url="https://www.manh.com/solutions/supply-chain-planning-software/inventory-allocation"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management003.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="back" 
      title="← Back"         
      geometry={nodes.Cylinder038.geometry} 
      material={materials['lvl2-large-clearblue-baked-texture']}
      position={[-4.777, -13.8, -7.011]}
      dest="01"
      isDirection="up"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      isActive={isActive}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      >
        {/* <mesh geometry={nodes.Warehouse_Management005.geometry} material={materials['White text.003']} position={[0, .414, 0]} scale={0.75} /> */}
      </Hex>
 </group>
  )
}

useGLTF.preload('./glb/supply-chain-planning-lvl2-empty2.glb')
