/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 ./public/stage.glb 
*/

import React, { useLayoutEffect,useRef, useEffect } from 'react'
import { useGLTF, Text } from '@react-three/drei'
import gsap from "gsap";
import HexSmall from './components/HexSmall/HexSmall'
import * as THREE from 'three'

export function Stage(props) {
  const { nodes, materials } = useGLTF('./glb/stage.glb')

  const stageCtx = props.stageCtx
  const component = useRef()
  const context = useRef()

  useEffect(() => {
    stageCtx(context.current)  
  },[])

  const titles = [
    `Barcode`,
    `Time\nClock`,
    `Payment\nGateway`,
    `Consolidation`,
    `Fulfillment`,
    `Edge`,
    `Cubing`,
    `Zebra`,
    `Email`,
    `Item`,
    `Flow\nThrough`,
    `Receipts`,
    `Routing`,
    `Tracking`,
    `Put\nAway`,
    `Scheduler`,
    `ProActive`,
    `Nexus`,
    `Common\nUtility`,
    // `Consolidation`,
    `Receiving`,
    `Printing`,
    `Fulfillment`,
    `Distance\nTime`,
    `Yard`,
    `Cubing`,
    `WES`,
    `Slotting`,
    `Nexus`,
    `Config`,
    `Alert\nNotification`,
    `Geo`,
    `Payment\nGateway`,
    `Flow\nThrough`,
    `Eureka`,
    `Zuul\nServer`,
    `Barcode`,
    `Time\nClock`,
    `Payment\nGateway`,
    // `Consolidation`,
    `Fulfillment`,
    `Edge`,
    `Cubing`
  ]

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new THREE.MeshPhysicalMaterial();          
        THREE.MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new THREE.MeshBasicMaterial();          
        THREE.MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.35
      });
      tl.addLabel('flipHexes');  
      let tl2 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      let tl3 = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      tl3.addLabel('animateText');
      let hexSmall = []
      let hexSmall2 = []
      let rDel = 0.35
      // let smDur = 0.3
      // 0.25
      // let mdDur = 0.4
      // 0.4
      let lgDur2 = 0.55
      // 0.5
      // let lgDur = 0.65
      // let rDelLg = 1.2
      // let rDelSm = 0.25

      let j = 0
      component.current.children?.forEach((mesh) => {  
        mesh.children.forEach((mesh) => { 
          if (mesh.isMesh && mesh.material === materials['honeycomb-20_BAKED']) {
            hexSmall.push(mesh); 
          }
          if (mesh.isMesh && mesh.name === 'wrap') {
            mesh.children.forEach((mesh) => { 
              mesh.material = copyMaterial(mesh)
              gsap.set(mesh.material, {transparent: true})
              mesh.text = titles[j]
              hexSmall2.push(mesh); 
            })
          }
          if (j === titles.length) {
            j = 0
          } else {
            j++
          }
        })
      })

      tl3
      .fromTo(hexSmall2.map(obj => obj.material), 
        {opacity: 0},
        {opacity: 0.4,
        stagger: {
          amount: 0.65,
          ease: "power2.inOut",
        },
        duration: 0.3,
        delay: 0.95,
        // onUpdate: function() {
        //   if (obj.material < 1) {
        //     obj.material.depthTest = false;
        //   } else {
        //     obj.material.depthTest = true;
        //   }
        // }   
        },'animateText+=' + lgDur2)
      
      self.add("animateText", () => {
        tl3.play('animateText')
      })
      self.add("animateTextRev", () => {
        // gsap.delayedCall(rDel, function(){    
          tl3.reverse();    
      //  });  
      });
      self.add("flipHexes", () => {
        // console.log(component.current)
        tl
        .to(component.current.rotation, {
          z: '+=' + Math.PI,
          duration: 1,
          delay: 0.33,
          ease: "back.in",
        },'flipHexes')

        tl.play('flipHexes')
      })
      self.add("flipHexesRev", () => {
        gsap.delayedCall(rDel, function(){    
          tl.reverse();    
       });  
      });
      self.add("animateSmallHexes", () => {
        let amt = 0.65
        let dur = 0.3
        tl2.addLabel('hexAnim');  
        tl2
        .to(hexSmall.map(obj => obj.scale), {
          x: '-=0.15',
          z: '-=0.15',
          stagger: {
            amount: amt,
            ease: "power2.inOut",
          },
          duration: dur
        },'hexAnim')
        .to(hexSmall.map(obj => obj.scale), {
          x: '+=0.15',
          z: '+=0.15',
          stagger: {
            amount: amt,
            ease: "power2.inOut",
          },
          duration: dur
        },'hexAnim+=' + dur)

        tl2.play('hexAnim')
      });
      self.add("animateSmallHexesRev", () => tl2.reverse());
    }, component);
    context.current = ctx;
    return () => ctx.revert();
  }, [component]);

  return (
    <group ref={component} {...props} dispose={null}>
      <HexSmall geometry={nodes.Cylinder.geometry} material={materials['honeycomb-20_BAKED']} position={[-5.282, 0, 4.037]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder001.geometry} material={materials['honeycomb-20_BAKED']} position={[-4.406, 0, 2.521]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder002.geometry} material={materials['honeycomb-20_BAKED']} position={[-3.527, 0, 4.035]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder004.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.91, 0, 2.521]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder005.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.031, 0, 4.035]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder006.geometry} material={materials['honeycomb-20_BAKED']} position={[-5.285, 0, 1.007]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder007.geometry} material={materials['honeycomb-20_BAKED']} position={[-6.161, 0, 2.523]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder008.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.04, 0, 1.009]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder010.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.909, 0, 5.549]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder013.geometry} material={materials['honeycomb-20_BAKED']} position={[-4.405, 0, 5.549]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder014.geometry} material={materials['honeycomb-20_BAKED']} position={[-5.285, 0, 7.069]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder015.geometry} material={materials['honeycomb-20_BAKED']} position={[-6.159, 0, 5.55]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder019.geometry} material={materials['honeycomb-20_BAKED']} position={[0.856, 0, 5.553]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder020.geometry} material={materials['honeycomb-20_BAKED']} position={[1.731, 0, 4.037]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder021.geometry} material={materials['honeycomb-20_BAKED']} position={[2.606, 0, 5.547]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder022.geometry} material={materials['honeycomb-20_BAKED']} position={[-2.648, 0, 5.553]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder023.geometry} material={materials['honeycomb-20_BAKED']} position={[-1.773, 0, 4.037]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder024.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.893, 0, 5.552]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder025.geometry} material={materials['honeycomb-20_BAKED']} position={[0.853, 0, 2.524]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder026.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.023, 0, 4.039]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder027.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.902, 0, 2.525]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder028.geometry} material={materials['honeycomb-20_BAKED']} position={[-1.771, 0, 7.065]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder029.geometry} material={materials['honeycomb-20_BAKED']} position={[-2.651, 0, 8.582]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder030.geometry} material={materials['honeycomb-20_BAKED']} position={[-3.525, 0, 7.066]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder031.geometry} material={materials['honeycomb-20_BAKED']} position={[1.733, 0, 7.065]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder032.geometry} material={materials['honeycomb-20_BAKED']} position={[0.853, 0, 8.582]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder033.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.021, 0, 7.066]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder035.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.897, 0, 8.58]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder036.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.017, 0, 10.094]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder037.geometry} material={materials['honeycomb-20_BAKED']} position={[-2.653, 0, 2.521]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder051.geometry} material={materials['honeycomb-20_BAKED']} position={[-3.532, 0, 1.002]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder052.geometry} material={materials['honeycomb-20_BAKED']} position={[1.727, 0, 1.005]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder053.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.027, 0, 1.006]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder054.geometry} material={materials['honeycomb-20_BAKED']} position={[-1.779, 0, 1.002]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder059.geometry} material={materials['honeycomb-20_BAKED']} position={[-4.411, 0, -0.509]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder060.geometry} material={materials['honeycomb-20_BAKED']} position={[-6.166, 0, -0.508]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder061.geometry} material={materials['honeycomb-20_BAKED']} position={[-2.658, 0, -0.514]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder063.geometry} material={materials['honeycomb-20_BAKED']} position={[0.847, 0, -0.51]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder064.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.905, 0, -0.514]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder065.geometry} material={materials['honeycomb-20_BAKED']} position={[4.36, 0, 5.546]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder066.geometry} material={materials['honeycomb-20_BAKED']} position={[2.602, 0, 2.518]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder067.geometry} material={materials['honeycomb-20_BAKED']} position={[2.609, 0, 8.573]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder069.geometry} material={materials['honeycomb-20_BAKED']} position={[3.48, 0, 4.032]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder070.geometry} material={materials['honeycomb-20_BAKED']} position={[3.482, 0, 7.06]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder062.geometry} material={materials['honeycomb-20_BAKED']} position={[-3.536, 0, -2.025]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder078.geometry} material={materials['honeycomb-20_BAKED']} position={[-5.291, 0, -2.024]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder079.geometry} material={materials['honeycomb-20_BAKED']} position={[-1.783, 0, -2.03]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder081.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.03, 0, -2.03]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder084.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.915, 0, -0.506]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder086.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.041, 0, -2.022]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder087.geometry} material={materials['honeycomb-20_BAKED']} position={[-6.166, 0, -3.538]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder071.geometry} material={materials['honeycomb-20_BAKED']} position={[5.235, 0, 4.027]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder080.geometry} material={materials['honeycomb-20_BAKED']} position={[6.985, 0, 4.021]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder088.geometry} material={materials['honeycomb-20_BAKED']} position={[5.232, 0, 0.998]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder089.geometry} material={materials['honeycomb-20_BAKED']} position={[4.356, 0, 2.513]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder090.geometry} material={materials['honeycomb-20_BAKED']} position={[3.477, 0, 0.999]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder091.geometry} material={materials['honeycomb-20_BAKED']} position={[6.11, 0, 2.512]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder092.geometry} material={materials['honeycomb-20_BAKED']} position={[6.112, 0, 5.539]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder093.geometry} material={materials['honeycomb-20_BAKED']} position={[6.106, 0, -0.521]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder094.geometry} material={materials['honeycomb-20_BAKED']} position={[4.352, 0, -0.52]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder095.geometry} material={materials['honeycomb-20_BAKED']} position={[2.6, 0, -0.524]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder096.geometry} material={materials['honeycomb-20_BAKED']} position={[1.721, 0, -2.04]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder097.geometry} material={materials['honeycomb-20_BAKED']} position={[5.226, 0, -2.036]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder098.geometry} material={materials['honeycomb-20_BAKED']} position={[3.474, 0, -2.04]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder100.geometry} material={materials['honeycomb-20_BAKED']} position={[6.981, 0, 0.993]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder102.geometry} material={materials['honeycomb-20_BAKED']} position={[7.859, 0, 2.506]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder103.geometry} material={materials['honeycomb-20_BAKED']} position={[7.861, 0, 5.534]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder104.geometry} material={materials['honeycomb-20_BAKED']} position={[0.843, 0, -3.551]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder105.geometry} material={materials['honeycomb-20_BAKED']} position={[2.596, 0, -3.556]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder106.geometry} material={materials['honeycomb-20_BAKED']} position={[4.349, 0, -3.556]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder107.geometry} material={materials['honeycomb-20_BAKED']} position={[-2.663, 0, -3.542]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder108.geometry} material={materials['honeycomb-20_BAKED']} position={[-4.417, 0, -3.541]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder109.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.909, 0, -3.548]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder110.geometry} material={materials['honeycomb-20_BAKED']} position={[5.232, 0, 7.059]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder125.geometry} material={materials['honeycomb-20_BAKED']} position={[-3.541, 0, -5.066]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder126.geometry} material={materials['honeycomb-20_BAKED']} position={[-5.296, 0, -5.065]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder127.geometry} material={materials['honeycomb-20_BAKED']} position={[-1.788, 0, -5.071]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder128.geometry} material={materials['honeycomb-20_BAKED']} position={[1.716, 0, -5.067]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder129.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.035, 0, -5.071]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder130.geometry} material={materials['honeycomb-20_BAKED']} position={[-2.666, 0, -6.582]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder132.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.913, 0, -6.587]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder133.geometry} material={materials['honeycomb-20_BAKED']} position={[0.84, 0, -6.587]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder138.geometry} material={materials['honeycomb-20_BAKED']} position={[5.221, 0, -5.077]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder139.geometry} material={materials['honeycomb-20_BAKED']} position={[3.47, 0, -5.081]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder140.geometry} material={materials['honeycomb-20_BAKED']} position={[2.588, 0, -6.594]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder148.geometry} material={materials['honeycomb-20_BAKED']} position={[-0.04, 0, -8.104]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder153.geometry} material={materials['honeycomb-20_BAKED']} position={[7.855, 0, -0.523]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder155.geometry} material={materials['honeycomb-20_BAKED']} position={[6.978, 0, -2.043]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder159.geometry} material={materials['honeycomb-20_BAKED']} position={[6.1, 0, -3.559]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder160.geometry} material={materials['honeycomb-20_BAKED']} position={[7.853, 0, -3.559]} scale={[1, 0.599, 1]} />
      <HexSmall geometry={nodes.Cylinder196.geometry} material={materials['honeycomb-20_BAKED']} position={[-7.919, 0, -3.539]} rotation={[Math.PI, 0, Math.PI]} scale={[1, 0.599, 1]} />
    </group>
  )
}

useGLTF.preload('./glb/stage.glb')
