import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshBasicMaterial,MeshPhysicalMaterial,Color } from 'three'
// import * as THREE from 'three'
import Hex from './components/Hex/Hex'
import gsap from "gsap";

export function ModelOmni(props) {
  const { nodes, materials } = useGLTF('./glb/omnichannel-lvl2-empty.glb')
  const active = props.active
  const isActive = props.isActive
  const anim = props.anim
  const isAnim = props.isAnim 
  const scene = props.scene
  const level = props.level
  const isLevel = props.isLevel
  const isScene = props.isScene
  const direction = props.direction
  const isDirection = props.isDirection
  const prev = props.prev
  const prevActive = props.prevActive
  const sceneCtx = props.sceneCtx
  const component = useRef()
  const context = useRef()

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      // console.log(mesh)
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new MeshPhysicalMaterial();          
        MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new MeshBasicMaterial();          
        MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  useEffect(() => {
    if (isScene === props.name && isActive === props.title) {
      sceneCtx(context.current)
      context.current.playTimeline()
    }
  },[isActive,isScene,props.name,props.title,sceneCtx])

  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.5
        });
        // let smDur = 0.3
        // 0.25
        let mdDur = 0.4
        // 0.4
        // let lgDur2 = 0.55
        // 0.5
        let lgDur = 0.65
        let dl = 0.6

      // 0.5
      // let xlDur = 0.75
      tl.addLabel('scene2');

      component.current.children.forEach((mesh) => {
        if (mesh.isGroup && mesh.name === 'order-management' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl  
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl
          }, 'scene2+=0.2')       
          .from(mesh.position, {
            x: '+=2.5',
            // z: '-=4.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              gsap.set(mesh.material, {transparent: true})
              mesh.material.visible = false
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,    
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }    
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#f1a04b");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 0.7
                    mesh.material.needsUpdate = true;
                  } 
                  mesh.material = copyMaterial(mesh)
                  gsap.set(mesh.material, {transparent: true})
                  mesh.material.visible = false
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }       
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'point-of-sale' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')   
          .from(mesh.position, {
            x: '+=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#f1a04b");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 0.7
                    mesh.material.needsUpdate = true;
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,  
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }        
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'back') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')
          .from(mesh.position, {
            x: '-=2.5',
            z: '-=2.5',
            duration: lgDur,
            ease: "back.in",
            delay: dl,
          }, 'scene2+=0.2')      
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }        
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }        
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'customer-service-engagement' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2') 
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')   
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl,  
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }              
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#f1a04b");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 0.7
                    mesh.material.needsUpdate = true;
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl, 
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }                
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'store-inventory-fulfillment' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')    
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl, 
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }               
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  // console.log(mesh.material)
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#f1a04b");
                    mesh.material.color.copy(color);
                    mesh.material.map = null
                    mesh.material.transmission = 1.1
                    mesh.material.needsUpdate = true;
                    mesh.material.ior = 1.4500000476837158
                    mesh.material.iridescenceIOR = 1.3
                    mesh.material.metalness = 0.7
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl, 
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }                
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
      })
      self.add("playTimeline", () => tl.play('scene2'));
      self.add("reverseTimeline", () => tl.reverse('scene2'));
    }, component);
    context.current = ctx;
    return () => ctx.revert();
  }, [isActive,prevActive,isDirection]);


  return (
    <group ref={component} {...props} dispose={null}>
      <Hex  
      name="order-management"  
      title={`Order\nManagement`}         
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[8, -13.8, 1.1]}
      // 2 lines
      titlePosition={[0, .414, -.2]}
      dest="03"
      isDirection="down"
      isActive={isActive}
      isAnim={isAnim}
      anim={anim}
      direction={direction}
      active={active}
      scale='1.25'
      prev={prev}
      prevActive={prevActive}
      scene={scene}
      level={level}
      isLevel={isLevel}
      url="https://www.manh.com/solutions/omnichannel-software-solutions/order-management-system"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh name="title" geometry={nodes.Warehouse_Management001.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>
      <Hex  
      name="point-of-sale" 
      title={`Point of Sale`}          
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[5.077, -13.8, -7.011]}
      // 2 lines
      titlePosition={[0, .414, -.2]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      url="https://www.manh.com/solutions/omnichannel-software-solutions/point-of-sale-software"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management002.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="store-inventory-fulfillment"          
      title={`Store Inventory\n& Fullfillment`} 
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[-7.877, -13.8, .86]}
      // titlePosition ={[0.11, 1,-0.023]}
      // 2 lines
      titlePosition={[0, .414, -.2]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      url="https://www.manh.com/solutions/omnichannel-software-solutions/store-inventory-fulfillment"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="customer-service-engagement"  
      title={`Customer Service\n& Engagement`}  
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[-3.5, -13.8, 7.25]}
      // 2 lines
      titlePosition={[0, .414, -.2]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      prev={prev}
      prevActive={prevActive}
      scene={scene}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      url="https://www.manh.com/solutions/omnichannel-software-solutions/customer-engagement-platform"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management003.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="back"  
      title="← Back"        
      geometry={nodes.Cylinder038.geometry} 
      material={materials['lvl2-large-clearblue-baked-texture']}
      position={[-4.777, -13.8, -7.011]}
      titlePosition ={[0.095, 1,0.25]}
      dest="01"
      isDirection="up"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      >
        {/* <mesh geometry={nodes.Warehouse_Management005.geometry} material={materials['White text.003']} position={[0, .414, 0]} scale={0.75} /> */}
      </Hex>
  </group>
  )
}

useGLTF.preload('./glb/omnichannel-lvl2-empty.glb')
