/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/supply-chain-execution-lvl2.glb 
*/

import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { useGLTF} from '@react-three/drei'
import { MeshBasicMaterial,MeshPhysicalMaterial,Color } from 'three'
// import * as THREE from 'three'
import Hex from './components/Hex/Hex'
import gsap from "gsap";

export function ModelActive(props) {
  const { nodes, materials } = useGLTF('./glb/supply-chain-execution-lvl2-empty.glb')
  const active = props.active
  const isActive = props.isActive
  const anim = props.anim
  const isAnim = props.isAnim 
  const scene = props.scene
  const level = props.level
  const isLevel = props.isLevel
  const isScene = props.isScene
  const direction = props.direction
  const isDirection = props.isDirection
  const prev = props.prev
  const prevActive = props.prevActive
  const sceneCtx = props.sceneCtx
  const component = useRef()
  const context = useRef()

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new MeshPhysicalMaterial();          
        MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new MeshBasicMaterial();          
        MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }

  useEffect(() => {
    if (isScene === props.name && isActive === props.title) {
      sceneCtx(context.current)
      context.current.playTimeline()
    }
  },[isActive,isScene,props.name,props.title,sceneCtx])

  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.5
        });
        // let smDur = 0.3
        // 0.25
        let mdDur = 0.4
        // 0.4
        // let lgDur2 = 0.55
        // 0.5
        let lgDur = 0.65
        let dl = 0.85

      // 0.5
      // let xlDur = 0.75
      tl.addLabel('scene2');

      component.current.children.forEach((mesh) => {
        if (mesh.isGroup && mesh.name === 'behavioral-intelligence' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')       
          .from(mesh.position, {
            x: '+=2.5',
            // z: '-=4.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')
          mesh.children[0].children.forEach((mesh) => {
            // console.log(mesh);
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              gsap.set(mesh.material, {transparent: true})
              mesh.material.visible = false
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,    
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#008ecb");
                    mesh.material.color.copy(color);
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'computational-intelligence' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')   
          .from(mesh.position, {
            x: '+=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,    
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene2+=' + mdDur)
              // console.log(mesh.material)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#008ecb");
                    mesh.material.color.copy(color);
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'back') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')
          .from(mesh.position, {
            x: '-=2.5',
            z: '-=2.5',
            duration: lgDur,
            ease: "back.in",
            delay: dl,
          }, 'scene2+=0.2')      
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,    
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                } 
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'developer-experience' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2') 
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')   
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl,    
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#008ecb");
                    mesh.material.color.copy(color);
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'data-analytics' && (mesh.name !== isActive && mesh.name !== prevActive) ) { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene2+=0.2')
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene2+=0.2')    
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl,    
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene2+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  if (mesh.material.name === 'orange_tile.001') {
                    const color = new Color("#008ecb");
                    mesh.material.color.copy(color);
                  } 
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }  
                  }, 'scene2+=' + mdDur)
                }
              })
            }
          })
        }
      })
      self.add("playTimeline", () => tl.play('scene2'));
      self.add("reverseTimeline", () => tl.reverse());
    }, component);
    context.current = ctx;
    return () => ctx.revert();
  }, [isActive,prevActive,isDirection]);


  return (
    <group ref={component} {...props} dispose={null}>
      <Hex  
      name="behavioral-intelligence"  
      title={`Behavioral\nIntelligence`}         
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[7.877, -13.8, 0]}
      dest="03"
      isDirection="down"
      isActive={isActive}
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      prev={prev}
      prevActive={prevActive}
      scene={scene}
      level={level}
      isLevel={isLevel}
      // titlePosition={[0.05, .414, -.2]} 
      url="https://www.manh.com/solutions/manhattan-active-platform/behavior-intelligence"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh name="title" geometry={nodes.Warehouse_Management001.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>
      <Hex  
      name="computational-intelligence" 
      title={`Computational\nIntelligence`}        
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[2, -13.8, -8]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      url="https://www.manh.com/solutions/manhattan-active-platform/computational-intelligence"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />

      </Hex>

      <Hex  
      name="data-analytics"
      title={`Data &\nAnalytics`}  
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[-7.877, -13.8, 0]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      // titlePosition={[.05, 1,-0.123]}
      titlePosition={[0.05, .414, -.2]} 
      url="https://www.manh.com/solutions/manhattan-active-platform/data-management"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="developer-experience"  
      title={`Developer\nExperience`} 
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl2-med-drkblue-baked']} 
      position={[-1.8, -13.4, 7.85]}
      dest="03"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      prev={prev}
      prevActive={prevActive}
      scene={scene}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      url="https://www.manh.com/solutions/manhattan-active-platform/developer-tools-api"
      >
        <mesh geometry={nodes.Cylinder034.geometry} material={materials['orange_tile.001']} position={[-.03, .404, 2.25]} scale="0.8" />
        {/* <mesh geometry={nodes.Warehouse_Management003.geometry} material={materials['White text.003']} position={[0, .414, -.464]} scale={0.75} /> */}
      </Hex>

      <Hex  
      name="back"  
      title="← Back"        
      geometry={nodes.Cylinder038.geometry} 
      material={materials['lvl2-large-clearblue-baked-texture']}
      position={[-4.777, -13.8, -7.011]}
      dest="01"
      isDirection="up"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      >
        {/* <mesh geometry={nodes.Warehouse_Management005.geometry} material={materials['White text.003']} position={[0, .414, 0]} scale={0.75} /> */}
      </Hex>
  </group>
  )
}

useGLTF.preload('./glb/supply-chain-execution-lvl2-empty.glb')
