/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.15 public/supply-chain-execution-lvl3.glb 
*/

import React, { useEffect,useLayoutEffect,useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { MeshBasicMaterial,MeshPhysicalMaterial,Color } from 'three'
// import * as THREE from 'three'
import Hex from './components/Hex/Hex'

import gsap from "gsap";

export function ModelActiveData(props) {
  const { nodes, materials } = useGLTF('./glb/supply-chain-execution-lvl3empty.glb')
  const active = props.active
  const anim = props.anim
  const isAnim = props.isAnim 
  const isActive = props.isActive
  const scene = props.scene
  const isScene = props.isScene
  const level = props.level
  const isLevel = props.isLevel
  const direction = props.direction
  const prev = props.prev
  const prevActive = props.prevActive
  const sceneCtx = props.sceneCtx
  const component = useRef()
  const context = useRef()

  function copyMaterial(mesh) {
    if (mesh.material !== undefined) {
      let prevMaterial = mesh.material; 
      let material
      if (mesh.material.type === 'MeshPhysicalMaterial') {
        material = new MeshPhysicalMaterial();          
        MeshPhysicalMaterial.prototype.copy.call( material, prevMaterial );
      } else {
        material = new MeshBasicMaterial();          
        MeshBasicMaterial.prototype.copy.call( material, prevMaterial );
      }
      return material
    }    
  }
  
  useEffect(() => {
    if (isScene === props.name && isActive === props.title) {
      sceneCtx(context.current)
      context.current.playTimeline()
    }
  },[isActive,isScene,props.name,props.title,sceneCtx])


  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
          autoRemoveChildren: false,
          smoothChildTiming: true,
          paused: true,
          delay: 0.5
        });
      tl.addLabel('scene3');

      // let smDur = 0.3
      // 0.25
      let mdDur = 0.4
      // 0.4
      // let lgDur2 = 0.55
      // 0.5
      let lgDur = 0.65
      let dl = 0.25

      component.current.children.forEach((mesh) => {
        if (mesh.isGroup && mesh.name === 'reporting-insights') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,       
          }, 'scene3')
          .from(mesh.position, {
            x: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material.visible = false;
              const color = new Color("#008ecb");
              mesh.material = copyMaterial(mesh);
              mesh.material.color.copy(color);
              mesh.material.map = null;
              // mesh.material.ior = 1
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0
              // mesh.material.roughness = 0.8
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, {
                opacity: 0,
                delay: dl,
                duration: mdDur,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }          
              }, 'scene3+=' + mdDur)
              mesh.children?.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    delay: dl,
                    duration: mdDur,    
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }       
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }

        if (mesh.isGroup && mesh.name === 'data-stream') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            // immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene3')
          .from(mesh.position, {
            x: '+=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material.visible = false;
              const color = new Color("#008ecb");
              mesh.material = copyMaterial(mesh);
              mesh.material.color.copy(color);
              mesh.material.map = null;
              // mesh.material.ior = 1
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0
              // mesh.material.roughness = 0.8
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }   
              }, 'scene3+=' + mdDur)
              mesh.children?.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }   
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'optimization') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          // gsap.set(mesh.position, {y: "+=3",x: 0,z: 0})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,        
          }, 'scene3') 
          .from(mesh.position, {
            x: '-=2.5',
            z: '+=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')   
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material.visible = false;
              const color = new Color("#008ecb");
              mesh.material = copyMaterial(mesh);
              mesh.material.color.copy(color);
              mesh.material.map = null;
              // mesh.material.ior = 1
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0
              // mesh.material.roughness = 0.8
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})

              // console.log(mesh.material)
              tl
              .from(mesh.material, { 
                opacity: 0,
                duration: mdDur,
                delay: dl,  
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }              
              }, 'scene3+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, { 
                    opacity: 0,
                    duration: mdDur,
                    delay: dl, 
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }                
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'data-save') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            duration: 0,
            delay: dl,
          }, 'scene3')
          .from(mesh.position, {
            x: '+=2.5',
            // z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material.visible = false;
              const color = new Color("#008ecb");
              mesh.material = copyMaterial(mesh);
              mesh.material.color.copy(color);
              mesh.material.map = null;
              // mesh.material.ior = 1
              mesh.material.transmission = 0.9
              // mesh.material.metalness = 0
              // mesh.material.roughness = 0.8
              mesh.material.needsUpdate = true;
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true,opacity:0.9})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene3+=' + mdDur)
              mesh.children?.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  // mesh.material.transmission = .2
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }   
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }
        if (mesh.isGroup && mesh.name === 'back') { 
          let hexMesh = mesh.children[0].children[0];
          hexMesh.visible = false;
          gsap.set(mesh.position, {y: "-=28"})
          tl        
          .to(mesh.position, { 
            y: '+=28',
            immediateRender: false,
            duration: 0,
            delay: dl,
          }, 'scene3')
          .from(mesh.position, {
            x: '-=2.5',
            z: '-=2.5',
            duration: lgDur,
            delay: dl,
            ease: "back.in",
          }, 'scene3')      
          mesh.children[0].children.forEach((mesh) => {
            if (mesh.isMesh) {
              mesh.material = copyMaterial(mesh)
              mesh.material.visible = false
              gsap.set(mesh.material, {transparent: true})
              tl
              .from(mesh.material, {
                opacity: 0,
                duration: mdDur,
                delay: dl,
                onUpdate: function() {
                  if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                    mesh.material.visible = true
                    hexMesh.visible = true;
                  } else {
                    mesh.material.visible = false
                    hexMesh.visible = false;
                  }
                }  
              }, 'scene3+=' + mdDur)
              mesh.children.forEach((mesh) => {
                if (mesh.isMesh) {
                  mesh.material = copyMaterial(mesh)
                  mesh.material.visible = false
                  gsap.set(mesh.material, {transparent: true})
                  tl
                  .from(mesh.material, {
                    opacity: 0,
                    duration: mdDur,
                    delay: dl,
                    onUpdate: function() {
                      if (mesh.material.opacity <= 1 && mesh.material.opacity !== 0) {
                        mesh.material.visible = true
                      } else {
                        mesh.material.visible = false
                      }
                    }   
                  }, 'scene3+=' + mdDur)
                }
              })
            }
          })
        }
      })
      self.add("playTimeline", () => tl.play('scene3'));
      self.add("reverseTimeline", () => tl.reverse());
    }, component);
    context.current = ctx;
    return () => ctx.revert();

  }, []);


  return (
    <group ref={component} {...props} dispose={null}>
     
      <Hex  
      title={`Data\nSave`}
      name="data-save"          
      geometry={nodes.Cylinder018.geometry} 
      material={materials['sce-lvl3-med-clearorange-baked.002']}
      position={[8, -13.8, 1.1]}
      dest="04"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      titlePosition={[-0.2, 1,0.1]}
      > 
      {/* <mesh geometry={nodes.Warehouse_Management002.geometry} material={materials['White text.003']} position={[.025, .414, -0.023]} scale={0.75} /> */}
      </Hex>

      <Hex  
      title={`Data\nStream`}
      name="data-stream"          
      geometry={nodes.Cylinder003.geometry} 
      material={materials['sce-lvl3-med-clearorange-baked.002']}
      position={[5.077, -13.8, -7.011]}
      dest="04"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      titlePosition={[-0.1, 1,0.1]}
      > 
      {/* <directionalLight color="red" position={[3.225, -19.459, 3.023]} rotation={[-1.913, 0.003, 0.706]} scale={0.393} /> */}
      {/* <pointLight intensity={1397.887} decay={2} position={[.025, -19.459, -0.023]} rotation={[-1.913, 0.003, 0.706]} scale={0.393} /> */}
      {/* <mesh geometry={nodes.Warehouse_Management001.geometry} material={materials['White text.003']} position={[.025, .414, -0.023]} scale={0.75} /> */}
      </Hex>

      <Hex  
      title={`Reporting\n& Insights`}
      name="reporting-insights"          
      geometry={nodes.Cylinder016.geometry} 
      material={materials['sce-lvl3-med-clearorange-baked.002']}
      position={[-7.877, -13.8, .86]}
      dest="04"
      isDirection="down"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      titlePosition={[0.15, 1,0.1]}
      > 
      {/* <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[.025, .414, -0.023]} scale={0.75} /> */}
      </Hex>
      
      {/* <mesh geometry={nodes.Cylinder016.geometry} material={materials['sce-lvl3-med-clearorange-baked.002']}position={[-5.728, -15.627, 1.889]} /> */}
     

      {/* <mesh geometry={nodes.Cylinder003.geometry} material={materials['sce-lvl3-med-orange-baked']} position={[3.696, -15.627, 5.797]} />
      <mesh geometry={nodes.Cylinder016.geometry} material={materials['sce-lvl3-med-orange-baked']} position={[-6.711, -15.627, 1.975]} />
      <mesh geometry={nodes.Warehouse_Management004.geometry} material={materials['White text.003']} position={[-6.747, -15.244, 1.833]} scale={0.75} />
      */}
      <Hex  
      name="back"     
      title="← Back"     
      geometry={nodes.Cylinder038.geometry} 
      material={materials['lvl2-large-clearblue-baked-texture']}
      position={[-4.777, -13.8, -7.011]}
      dest="02"
      isDirection="up"
      direction={direction}
      active={active}
      isAnim={isAnim}
      anim={anim}
      scale='1.25'
      scene={scene}
      prev={prev}
      prevActive={prevActive}
      level={level}
      isLevel={isLevel}
      isActive={isActive}
      >
        {/* <mesh geometry={nodes.Cylinder038.geometry} material={materials['lvl2-large-clearblue-baked-texture']} position={[-4.077, -15.627, -5.362]} />  */}
        {/* <mesh geometry={nodes.Back.geometry} material={materials['White text.003']} position={[0, .414, 0]} scale={0.75} /> */}
      </Hex>
  </group>
  )
}


useGLTF.preload('./glb/supply-chain-execution-lvl3empty.glb')
