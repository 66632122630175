import { useLayoutEffect, useRef } from 'react'
import { Text } from '@react-three/drei'
import gsap from "gsap";
import { suspend } from 'suspend-react'
  
const bold = import('../../assets/HelveticaNowDisplayXBd.ttf')

function HexSmall({geometry, material,rotation, children, ...props}) {
  const text = props.text 
  const textColor = props.textColor
  const component = useRef()
  const context = useRef()

  useLayoutEffect(() => {
    let ctx = gsap.context((self) => {
      let tl = gsap.timeline({
        autoRemoveChildren: false,
        smoothChildTiming: true,
        paused: true,
      });
      tl.addLabel('animateText');

      self.add("playTimeline", () => {

        tl.play('animateText')
      })
      self.add("reverseTimeline", () => tl.reverse());
    }, component);
    context.current = ctx;
    return () => ctx.revert();
  }, []);

  return (
    <group ref={component} {...props}>
      <mesh
        geometry={geometry}
        material={material}  
        name="wrap" 
        >
        <Text 
        fontSize={0.25}
        textAlign= "center"
        color={textColor ? textColor : 'white' }
        font={suspend(bold).default}
        anchorX="center" 
        lineHeight={1.1} 
        rotation={rotation ? [-1.5,  Math.PI, 0] : [-1.5, Math.PI, 0]}
        letterSpacing= {0.05}
        extrude={.01}
        materialType= "MeshPhongMaterial"
        material-toneMapped={false}>
          {text}
        </Text>
      </mesh>
    </group>
  )
}

export default HexSmall